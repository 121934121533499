<template>
    <div class="auth-component-form-input gr-19 push-1 gr-20@small">
        <div>
            <input id="password" name="password" type="password"
                   placeholder="Neues Passwort" autocomplete="off" @change="saveData">
            <div v-if="!validity.password" class="auth-error">Dein Passwort muss mindestens 8 Zeichen lang sein.</div>
        </div>
    </div>

    <div>
        <div class="auth-component-action gr-19 push-1 gr-20@small">
            <base-button type="submit" class="auth-button button">zurücksetzen</base-button>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['validity'],
        emits: ['saveUser'],
        data() {
            return {
                userData: {
                    password: '',
                }
            }
        },
        methods: {
            saveData(event) {
                this.userData[event.target.id] = event.target.value;
                this.$emit('saveUser', this.userData);
            },
        }
    }
</script>


<style scoped>
</style>
